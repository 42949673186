:root {
  --primary: #396b9a;
  --accent: #396b9a;
  --brand: #fa8152;
  --background: #222b45;
  --warning: #ffc107;
  --success: #12c99b;
  --error: #e41749;
  --dark: #151a30;
  --bs-link-color: #fff !important;
  --bs-link-hover-color: #fff !important;
}

header {
  background-color: var(--primary);
}

header .btn-link {
  text-decoration: none;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.controls {
  margin-bottom: 2rem;
}

blockquote {
  color: #666;
  margin: 0;
  padding-left: 3em;
  border-left: 0.5em #eee solid;
}

.button-normal {
  font-size: 1.2rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: none;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button-small {
  font-size: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button:hover {
  background-color: #ddd;
}

.error {
  color: red;
  font-weight: bold;
  margin-bottom: 1rem;
}

.transcription {
  margin-top: 2rem;
  white-space: pre-wrap;
}

.list-chapters {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  text-align: left;
}

.App-output {
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem;
  text-align: left;
}

input {
  margin-left: 20px;
  width: 200px;
}

.accent-button {
  align-items: center;
  background-clip: padding-box;
  background-color: var(--accent);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  min-width: 200px;
  height: 50px;
}

.document-list-item:hover {
  cursor: pointer;
}

.accent-button.cancel {
  align-items: center;
  background-clip: padding-box;
  background-color: transparent;
  border: 1px solid var(--brand);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: var(--brand);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.accent-button.cancel {
  align-items: center;
  background-clip: padding-box;
  background-color: transparent;
  border: 1px solid var(--brand);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: var(--brand);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.accent-button:hover,
.accent-button:focus {
  background-color: #5089be;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.accent-button.cancel:hover,
.accent-button.cancel:focus {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  border: 1px solid var(--brand);
  color: var(--brand);
  background-color: transparent;
}

.accent-button:disabled {
  background-color: var(--brand);
}

.accent-button:hover {
  transform: translateY(-1px);
}

.accent-button:active {
  background-color: #5089be;
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}

.App .lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 24px;
  margin-bottom: 40px;
  padding-right: 10px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  border: 4px solid #ff6f00;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff6f00 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.processing {
  position: center;
}

.secondary-button {
  appearance: none;
  background-color: #6c757d;
  border: none;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 10px 20px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1),
    transform 0.1s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.secondary-button:hover {
  background-color: #6c757d;
  text-decoration: none;
  transition-duration: 0.1s;
  transform: translateY(-1px);
}

.secondary-button:disabled {
  background-color: #d9d9d9;
  border: none;
  color: #4d4d4d;
  cursor: default;
}

.secondary-button:active {
  background-color: #6c757d;
  box-shadow: none;
  transition: none 0s;
  transform: translateY(1px);
}

.secondary-button:focus {
  outline: none;
}

.secondary-button:before {
  display: none;
}

.secondary-button:-webkit-details-marker {
  display: none;
}

ol,
ul {
  padding: 1rem !important;
  margin: 0 !important;
}

.App .modal {
  background-color: rgba(0, 0, 0, 0.4);
  display: block;
  z-index: 10;
}

.App .modal .modal-content {
  z-index: 20;
}

.App .btn-outline-secondary {
  --bs-btn-color: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.App .btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--primary);
  --bs-btn-disabled-border-color: var(--primary);
}

.App .copy-to-clipboard {
  position: relative;
}

.App .copy-to-clipboard .popover {
  width: 195px;
  padding: 10px;

  position: absolute;
  top: -50px;
  right: -10px;
}

.App .copy-to-clipboard .popover .popover-body {
  padding: 0;
}

.App .text-primary {
  color: var(--primary) !important;
}

.App .alert-topic {
  --bs-alert-color: #2d2318;
  --bs-alert-bg: #fdf5ee;
  --bs-alert-border-color: #fdf5ee;
}

.App .dropdown-item.active,
.App .dropdown-item:active {
  color: var(--primary);
  text-decoration: none;
  background-color: transparent;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
  .App {
    width: 100%;
  }

  .button-small {
    padding: 1rem;
    font-size: 2.2rem;
  }

  .accent-button {
    width: 100%;
    height: 60px;
    font-size: 20px;
  }
}
